import React, { useState } from 'react'
import { Box, Fab, styled, TextField, Typography } from '@mui/material'
import axios from 'axios'

interface Bike {
  name: string
  lat: number
  lng: number
}

const MainBox = styled(Box)`
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
`


const App = () => {

  const [search, setSearch] = useState<string>('')

  const getBikesFromData = (data: any) => {
    return data.countries[0].cities[0].places.filter((place: any) => place.bike) as Bike[]
  }

  const openGoogleMaps = (bike: Bike) => {
    const url = `https://maps.google.com/?q=${bike.lat},${bike.lng}`

    setTimeout(() => {
      window.open(url, '_blank')

    })
  }

  const showInGoogleMaps = () => {
    axios.get('https://maps.nextbike.net/maps/nextbike-live.json?city=21&city=634&domains=fg,fg&list_cities=0&bikes=0')
      .then(({ data }) => {

        const bikes = getBikesFromData(data)
        const filteredBikes = bikes.filter(b => b.name === `BIKE ${search}`)

        switch (filteredBikes.length) {
          case 0:
            alert('Bike not found')
            break
          case 1:
            openGoogleMaps(filteredBikes[0])
            break
          default:
            alert('Multiple bikes found. Please enter the complete bike number')
        }
      })
  }

  return (
    <MainBox>
      <Box>
        <Typography variant='h4'>Next Finder</Typography>
        <Typography variant='subtitle1'>Karlsruhe</Typography>
      </Box>

      <TextField value={search} onChange={e => setSearch(e.target.value)}
                 type='number' variant='outlined' color='primary'
                 label='Fahrrad-Nummer' />
      <Fab onClick={showInGoogleMaps} variant='extended' color='primary'>
        In Google Maps anzeigen
      </Fab>
    </MainBox>
  )
}

export default App
